.card{
    position: relative;
}

.card img{
    width: 100%;
    display: flex;
    border: 2px solid #fff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}

button:hover {
    background: #c23866;
    color: #fff;
  }

.card .front{
    transform: rotateY(90deg);
    position: absolute;
    transition: all ease-in .2s;
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -webkit-transition: all ease-in .2s;
    -moz-transition: all ease-in .2s;
    -ms-transition: all ease-in .2s;
    -o-transition: all ease-in .2s;
}

.flipped .front {
    transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    transition-delay: .2s;
}

.card .back{
    transition: all ease-in .2s;
    -webkit-transition: all ease-in .2s;
    -moz-transition: all ease-in .2s;
    -ms-transition: all ease-in .2s;
    -o-transition: all ease-in .2s;
    transition-delay: .2s;
}

.flipped .back{
    transition-delay: 0s;
    transform: translateX(90deg);
    -webkit-transform: translateX(90deg);
    -moz-transform: translateX(90deg);
    -ms-transform: translateX(90deg);
    -o-transform: translateX(90deg);
}
