@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


body{
  font-family: Raleway, sans-serif;
  margin: 0;
  font-size: 1.5rem;
  text-align: center;
  background: #1b1523;
  color: #fff;
}